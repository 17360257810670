<template>
  <section class="container">
    <div class="sectionHeader container">
      <div class="sectionHeading">
        <h3>Projekt {{ projectItem.project?.title }}</h3>
      </div>
    </div>
    <div class="projectWrapper" data-aos="fade-right" data-aos-delay="250">
      <div class="projectColumn">
        <div class="gallery">
          <div class="thumbnails">
            <a
              v-for="galleryItem in projectItem.gallery"
              :key="galleryItem.id"
              :href="galleryItem.foto"
              data-fancybox="gallery"
              :data-caption="galleryItem.description"
            >
              <img :src="galleryItem.foto" :alt="galleryItem.description" />
            </a>
          </div>
        </div>
      </div>
      <div class="projectColumn">
        <p v-html="projectDesc"></p>
      </div>
    </div>

    <div class="technicaParametersWrapper">
      <div
        class="technicaParametersColumn1"
        data-aos="fade-up"
        data-aos-delay="250"
      >
        <div class="sectionHeader container">
          <div class="sectionHeading">
            <h3>Parametry</h3>
          </div>
        </div>
        <div
          class="technicaParameters"
          v-for="paramItem in projectItem.parameters"
          :key="paramItem.id"
        >
          <div>{{ paramItem.title }}</div>
          <div>{{ paramItem.description }}</div>
        </div>
      </div>
      <div
        class="technicaParametersColumn2"
        data-aos="fade-up"
        data-aos-delay="260"
      >
        <div class="sectionHeader container">
          <div class="sectionHeading">
            <h3>Atuty</h3>
          </div>
        </div>
        <ul>
          <li v-for="mainItem in projectItem.atuty" :key="mainItem.id">
            <i class="ri-checkbox-line"></i> {{ mainItem.title }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ProjectView",
  data() {
    return {
      projectId: null,
      projectDesc: "",
      projectItem: [],
    };
  },
  methods: {
    async getListProjectById() {
      const projectId = this.$route.params.projectId;
      try {
        const response = await this.$axios.get(
          "?api&action=getListProjectById&projectId=" + projectId
        );
        this.projectItem = response.data;
        this.projectDesc = this.projectItem.project.description;
        console.log(this.projectItem);
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.$fancybox.bind(); // Opcjonalnie, jeśli chcesz ręcznie związać fancybox
    this.getListProjectById();
  },
};
</script>
<style>
.projectWrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  .projectColumn {
    flex: 1;

    p {
      text-align: justify;
      line-height: 30px;
    }
  }
}

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.thumbnails img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnails img:hover {
  transform: scale(1.05);
}

.technicaParametersWrapper {
  display: flex;
  line-height: 30px;
  column-gap: 40px;
  margin-bottom: 25px;

  .technicaParametersColumn1 {
    flex: 1;

    .technicaParameters {
      display: flex;
      justify-content: space-between;
    }
  }

  .technicaParametersColumn2 {
    flex: 2;

    ul {
      column-count: 2;

      i {
        color: var(--secondary-color);
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .projectWrapper {
    flex-direction: column;
  }
  .sectionHeader {
    margin: 40px auto 0px;
  }
  .technicaParametersWrapper {
    flex-direction: column;
    .technicaParametersColumn2 {
      ul {
        column-count: 1;
      }
    }
  }
}
</style>
